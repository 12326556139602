
import Vue from "vue";
import {
  emailRule,
  letterCountRule,
  minLengthRule,
  phoneRule,
  requiredRule,
  spaceCheckRule
} from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import moment from "moment";
import Rewardful from "vue-rewardful";
Vue.use(Rewardful, {
  key: "e9e80c"
});

export default Vue.extend({
  name: "RegisterClient",

  directives: {
    mask
  },

  data: () => ({
    model: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
      client_name: "API Password Grant Client"
    },
    acceptPolicy: false,
    infoDialog: false,
    showInfoButton: false,
    errorMessages: {},
    showPassword: {
      current: false as boolean,
      repeat: false as boolean
    } as { [value: string]: boolean },
    errors: {},
    loading: false
  }),

  computed: {
    copyrightYear() {
      return moment().year();
    },
    phone() {
      return process.env.VUE_APP_PHONE;
    },
    rules() {
      return {
        emailRule: emailRule.bind(this),
        requiredRule: requiredRule.bind(this),
        minLengthRule: minLengthRule.bind(this),
        phoneRule: phoneRule.bind(this),
        spaceCheckRule: spaceCheckRule.bind(this),
        letterCountRule: letterCountRule.bind(this),
        identityPassword: (v: string): string | boolean =>
          v === this.model.password || "Password mismatch"
      };
    },
    newModel() {
      const currentModel = this.model;
      return {
        name:
          currentModel.first_name.trim() + "‎ " + currentModel.last_name.trim(),
        email: currentModel.email,
        phone: currentModel.phone,
        password: currentModel.password,
        password_confirmation: currentModel.password_confirmation,
        client_name: "API Password Grant Client"
      };
    }
  },

  mounted() {
    setTimeout(() => {
      this.showInfoButton = true;
    }, 1000);
  },

  methods: {
    toggleShowPassword(type: string) {
      this.showPassword[type] = !this.showPassword[type];
    },
    submit() {
      this.model.email = this.model.email.replaceAll(" ", "");
      this.$nextTick(async () => {
        if ((this.$refs.form as Vue).validate()) {
          try {
            await this.$API.auth().registerClient(this.newModel);
            (this.$refs.form as Vue).removeGuard();

            const response = await this.$API.auth().login(this.newModel);
            await this.$store.dispatch("authentication/login", response);
            document.location.href = origin;
          } catch (e) {
            if (e.hasOwnProperty("errors")) {
              this.errorMessages = e.errors;
              setTimeout(() => {
                this.errorMessages = {};
              }, 3000);
            }
            await this.$store.dispatch("alert/showError", e.message);
          }
        }
      });
    }
  }
});
